import request from '@/utils/requestV2'

// 统计品牌数-按站点分组
export function countBrandNumberGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/countBrandNumberGbStation',
    method: 'get',
    params: data
  })
}
// 统计上刊率/预售率-按站点分组
export function listResourceRateGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/listResourceRateGbStation',
    method: 'get',
    params: data
  })
}
// 统计上刊率-预售率-按站点分组
export function listBookRateGbStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/listBookRateGbStation',
    method: 'get',
    params: data
  })
}
// 查询站点的价值
export function computeStationValue (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/computeStationValue',
    method: 'get',
    params: data
  })
}
// 站点数据对比(上刊率-客流量-执行额)
export function computeAssetResourceUsedRateAndPassengerFlow (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/computeAssetResourceUsedRateAndPassengerFlow',
    method: 'get',
    params: data
  })
}
// 查询站点的客流、执行额、去化率按月分组
export function listStationUsedRateAndExecuteGbMonth (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/listStationUsedRateAndExecuteGbMonth',
    method: 'get',
    params: data
  })
}
// 统计上刊率/预售率-按站点分组-合并换乘站数据
export function listResourceRateGbStationName (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/listResourceRateGbStationName',
    method: 'get',
    params: data
  })
}
// 统计品牌数-按站点分组-合并换乘站数据
export function countBrandNumberGbStationName (data) {
  return request({
    url: '/ooh-dw/v1/datav/station/countBrandNumberGbStationName',
    method: 'get',
    params: data
  })
}
